import { generateMapByOpts, mapHelper } from 'common/utils'

// 状态
const cardStatus = [
  {
    text: '正常',
    value: 1
  }, {
    text: '关闭',
    value: 2
  }
]

const {
  map: cardStatusMap,
  setOps: setCardStatusOps
} = mapHelper.setMap(cardStatus)
// 跳转类型
const skipType = [
  {
    text: '自定义',
    value: 4
  }, {
    text: '至外部链接',
    value: 3
  }
]

const {
  map: skipTypeMap,
  setOps: setSkipTypeOps
} = mapHelper.setMap(skipType)

// 项目状态
const communityStatus = [
  {
    text: '正常',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  }
]

const {
  map: communityStatusMap,
  setOps: setCommunityStatusOps
} = mapHelper.setMap(communityStatus)
// 区域类型
const regionTypeOps = [
  {
    text: '全部',
    value: null
  }, {
    text: '分子公司',
    value: 0
  }, {
    text: '园区公司分公司',
    value: 1
  }, {
    text: '管理项目组',
    value: 2
  }
]

const regionTypeMap = generateMapByOpts(regionTypeOps)
export {
  cardStatusMap,
  setCardStatusOps,
  skipTypeMap,
  setSkipTypeOps,
  communityStatusMap,
  setCommunityStatusOps,
  regionTypeOps,
  regionTypeMap
}
