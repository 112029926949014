<template>
  <div class="CardMgrList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
      :selectable="selectable"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="名称" v-model="searchParams.title"></v-input>
        <v-select label="跳转类型" v-model="searchParams.skipType" :options="skipTypeOps"></v-select>
        <v-select2 label="发布项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select label="卡片状态" v-model="searchParams.cardStatus" :options="cardStatusOps"></v-select>
        <v-select2 label="所属分公司" v-model="searchParams.regionId" v-bind="regionParams"></v-select2>
        <v-datepicker type="rangedatetimer" label="上线时间段" :startTime.sync="searchParams.startTime" :endTime.sync="searchParams.endTime"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button v-if="scope.row.cardStatus === 2" text="查看" type="text" permission="update" @click="edit(scope.row)"></v-button>
        <v-button v-if="scope.row.cardStatus === 1" text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
        <v-button v-if="scope.row.cardStatus === 1" text="关闭" type="text" permission="delete" @click="closeCard(scope.row)"></v-button>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量关闭" :disabled="scope.selectedData.ids.length === 0" @click="batch(scope.selectedData)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, batchURL, updataURL } from './api'
import {
  cardStatusMap,
  setCardStatusOps,
  skipTypeMap,
  setSkipTypeOps
} from './map'
import {
  communityParams, regionParams
} from 'common/select2Params'
export default {
  name: 'CardMgrList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      cardStatusOps: setCardStatusOps(1),
      skipTypeOps: setSkipTypeOps(1),
      communityParams,
      regionParams,
      searchParams: {
        title: '',
        skipType: undefined,
        communityId: '',
        cardStatus: undefined,
        regionId: '',
        startTime: '',
        endTime: ''
      },
      headers: [
        {
          prop: 'title',
          label: '名称'
        },
        {
          prop: 'skipTypeText',
          label: '跳转类型',
          formatter (row) {
            return skipTypeMap[row.skipType]
          }
        },
        {
          prop: 'communityName',
          label: '发布项目'
        },
        {
          prop: 'readCount',
          label: '阅读数',
          align: 'right'
        },
        {
          prop: 'sort',
          label: '显示位置'
        },
        {
          prop: 'userName',
          label: '停留时间',
          formatter (row) {
            return `${row.stayBeginTime}-${row.stayEndTime}`
          }
        },
        {
          prop: 'statusText',
          label: '状态',
          formatter (row) {
            return cardStatusMap[row.cardStatus]
          }
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ],
      putHeader: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      }
    }
  },
  methods: {
    selectable (row, index) {
      return row.cardStatus !== 2
    },
    updateStatus (ids) {
      let _ids = ids.join(',')
      let postData = {
        ids: _ids
      }
      this.$axios.put(batchURL, this.$qs.stringify(postData), this.putHeader).then(res => {
        if (res.status === 100) {
          this.$refs.list.searchData()
          this.$message.success('操作成功')
        }
      })
    },
    async batch (selected) {
      let isOk = await this.$confirm('确定执行该操作？')
      isOk && this.updateStatus(selected.ids)
    },
    create () {
      this.$router.push({
        name: 'cardMgrForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'cardMgrForm',
        query: {
          id: row.id
        }
      })
    },
    async closeCard (row) {
      let isOk = await this.$confirm('是否关闭该卡片？')
      isOk && this.$axios.put(`${updataURL}/${row.id}`).then(res => {
        if (res.status === 100) {
          this.$refs.list.searchData()
          this.$message.success('操作成功')
        }
      })
    }
  }
}
</script>
